<template>
  <div>
    <van-nav-bar :title="title" />
    <van-cell v-for="(item, index) in dataList"
              :key="index">
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <span>{{ item.date }}</span>
        <br />
        <span> {{ item.text }}</span>
      </template>
    </van-cell>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userId: '',
      type: '',
      dataList: [],
      title: '',
    }
  },
  methods: {
    queryMethod() {
      var url = ''
      let param = {}
      if (!this.type) {
        return
      }
      switch (this.type) {
        case 'search':
          url = `${this.$dataUrl}/api/nvi/judgementSearchHistory`
          param.user_id = this.userId
          break
        case 'case':
          url = `${this.$base}/fission/page/queryCase?userId=${this.userId}`
          break
        case 'analy':
          url = `${this.$dataUrl}/api/user/analyse_his`
          param.user_id = this.userId
          break
        default:
          break
      }
      console.log('----------')
      console.log(url)
      console.log(param)
      this.$axios.post(url, param).then((res) => {
        switch (this.type) {
          case 'search':
            if (res.muster) {
              res.muster.forEach((item) => {
                var str = ''
                item.query_condition.forEach((ch) => {
                  str += ch.key + ';'
                })
                this.dataList.push({
                  date: this.dayjs(item.create_at).format(
                    'YYYY-MM-DD hh:mm:ss'
                  ),
                  text: str,
                })
              })
            }
            break
          case 'case':
            if (res) {
              res.forEach((item) => {
                this.dataList.push({
                  date: this.dayjs(item.createTime).format(
                    'YYYY-MM-DD hh:mm:ss'
                  ),

                  text: item.caseName,
                })
              })
            }
            break
          case 'analy':
            if (res.result) {
              res.result.forEach((item) => {
                this.dataList.push({
                  date: this.dayjs(item.date_timestamp * 1000).format(
                    'YYYY-MM-DD hh:mm:ss'
                  ),
                  text: item.text,
                })
              })
            }
            break
        }
      })
    },
  },
  created() {
    this.userId = this.$route.query.id
    this.type = this.$route.query.type
  },
  mounted() {
    this.queryMethod()
    switch (this.type) {
      case 'search':
        this.title = '大数据检索历史'
        break
      case 'case':
        this.title = '创建案件历史'
        break
      case 'analy':
        this.title = '案情分析历史'
        break
      default:
        break
    }
  },
}
</script>
